@import "../../variables";

.mobile {
  display: none;
}

@media only screen and (max-width: $tablet) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

.footage {
  width: 100%;
  margin: 1.5rem 0;
  border-radius: 1rem;

  @media only screen and (max-width: $tablet) {
    border-radius: 0.5rem;
  }
}

.subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 41px;
  text-align: right;
  color: $black;

  @media only screen and (max-width: $desktop) {
    font-size: 41px;
  }

  @media only screen and (max-width: $laptop) {
    font-size: 35px;
  }

  @media only screen and (max-width: $tablet) {
    font-size: 30px;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 28px;
  }

  @media only screen and (max-width: $small) {
    font-size: 24px;
  }
}

.line {
  border: 2px solid $mid-blue;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: $tablet) {
    align-items: center;
    text-align: center;
  }
}

.product {
  width: 90%;
  margin: 0 5%;
}

.walking {
  width: 100%;
}
