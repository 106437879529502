@import "../../variables";

.button {
  text-decoration: none;
  border-radius: 125px;
  border: none;
  width: fit-content;
}

.button-blue,
.button-white {
  border-radius: 125px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: fit-content;
  padding: 0.5rem 2rem;
  transition: 0.3s ease;

  p {
    font-size: 1.8vw;
    text-align: center;
    margin: 0;
    margin-bottom: -0.35rem;
    text-transform: uppercase;

    @media only screen and (max-width: $desktop) {
      font-size: 26px;
    }

    @media only screen and (max-width: $laptop) {
      font-size: 24px;
    }

    @media only screen and (max-width: $tablet) {
      font-size: 22px;
    }

    @media only screen and (max-width: $mobile) {
      font-size: 20px;
    }

    @media only screen and (max-width: $small) {
      font-size: 18px;
    }
  }
}

.button-blue {
  background: #0000b7;
  box-shadow: 0.64px 1.28px 5.13px #282828;

  p {
    font-weight: bold;
    color: #ffffff;
  }
}

.button-white {
  background: #ffffff;
  border: 1.41727px solid #000000;
  box-shadow: 0.91px 1.81px 7.27px #282828;

  p {
    font-weight: normal;
    color: #000000;
  }
}
