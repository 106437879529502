@import "../../variables";

.summary {
  background: $mid-blue;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3.5% 4%;

  .description {
    font-style: normal;
    font-weight: bold;
    font-size: 2vw;
    line-height: 1.6;
    color: #ffffff;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: $desktop) {
      font-size: 30px;
    }

    @media only screen and (max-width: $laptop) {
      font-size: 28px;
    }

    @media only screen and (max-width: $tablet) {
      line-height: 1.5;
      font-size: 26px;
    }

    @media only screen and (max-width: $mobile) {
      font-size: 24px;
    }

    @media only screen and (max-width: $small) {
      font-size: 22px;
    }
  }

  h6 {
    margin-bottom: 1.5rem;
    color: $white;
  }
}
