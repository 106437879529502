@import "../../variables";

.navbar {
  .dropdown-toggle {
    height: fit-content;
    margin-left: 1.6rem;
    text-decoration: none;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #777777;
    transition: 0.2s ease;
    padding: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:hover {
      color: $black;
    }
  }

  .dropdown-menu {
    border: none;
    text-align: right;
  }

  .dropdown-item {
    &:hover {
      background-color: #e9e9e9;
    }
    &:active {
      background-color: #e9e9e9;
    }
  }
}
