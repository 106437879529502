@import "../../variables";

.splash {
  background: $dark-blue;

  img {
    width: 90%;
    margin: 0 5%;
  }
}

.subtitle {
  text-align: center;
  color: $mid-light-blue;
}

.page-graphic {
  img {
    width: 100%;
    padding: 0;

    @media only screen and (max-width: $tablet) {
      transform: scale(1.3);
    }

    @media only screen and (max-width: $mobile) {
      transform: scale(2);
    }
  }
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 7%;

  @media only screen and (max-width: $tablet) {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    margin-bottom: 1rem;
  }

  p {
    color: $black;
    margin-top: 1rem;
  }
}

.about-image {
  img {
    width: 100%;
    border-radius: 0.5rem;
  }
}

.description {
  margin-top: 2.5rem;

  @media only screen and (max-width: $tablet) {
    margin-top: 1.25rem;
  }
}
