@import "./variables";

// .row {
//   border: 1px solid blue;
// }
// .col,
// .col-6,
// .col-4 {
//   border: 1px solid blue;
// }

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 140px;
  line-height: 214px;
}

.subtitle-1 {
  font-style: normal;
  font-weight: 600;
  font-size: 99.4126px;
  line-height: 152px;
  color: $dark-blue;
}

.subtitle-2 {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 3.5rem;

  @media only screen and (max-width: $desktop) {
    font-size: 3.5rem;
  }

  @media only screen and (max-width: $laptop) {
    font-size: 3rem;
  }

  @media only screen and (max-width: $tablet) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 2rem;
  }

  @media only screen and (max-width: $small) {
    font-size: 2rem;
  }
}

.subtitle-3 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 3vw;
  line-height: 1.6;

  @media only screen and (max-width: $desktop) {
    font-size: 42px;
  }

  @media only screen and (max-width: $tablet) {
    line-height: 1.5;
    font-size: 32px;
  }
}

.text {
  font-style: normal;
  font-weight: 300;
  font-size: 1.75vw;
  line-height: 1.7;

  @media only screen and (max-width: $desktop) {
    font-size: 24px;
  }

  @media only screen and (max-width: $laptop) {
    font-size: 22px;
  }

  @media only screen and (max-width: $tablet) {
    font-size: 20px;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 18px;
  }
}

.text-small {
  font-style: normal;
  font-weight: 300;
  font-size: 1.5vw;
  line-height: 1.7;

  @media only screen and (max-width: $desktop) {
    font-size: 22px;
  }

  @media only screen and (max-width: $laptop) {
    font-size: 20px;
  }

  @media only screen and (max-width: $tablet) {
    font-size: 18px;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 17px;
  }
}
