@import "../../variables";

.footer {
  background: #f6f6f6;
  min-height: 5vh;

  a {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    text-decoration: none;
    color: #000000;
    height: fit-content;
    transition: 0.2s ease;

    &:hover {
      text-decoration: underline;
    }

    @media only screen and (max-width: 600px) {
      font-size: 18px;
    }
  }

  .left {
    display: flex;

    a {
      margin-right: 1rem;
    }

    @media only screen and (max-width: 768px) {
      justify-content: center;

      a {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
      }
    }
  }

  .center {
    img {
      width: 70%;
      margin: 1rem 15%;
    }

    @media only screen and (max-width: 992px) {
      width: 30%;
      margin: 0rem 35%;
    }

    @media only screen and (max-width: 992px) {
      width: 50%;
      margin: 0rem 25%;
    }

    @media only screen and (max-width: 768px) {
      width: 50%;
      margin: 0.5rem 25%;
    }
    @media only screen and (max-width: 600px) {
      width: 80%;
      margin: 0.5rem 10%;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
      width: 100%;
      margin: 0.5rem 0;
      margin-bottom: 0;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;

    a {
      margin-left: 1rem;
    }

    @media only screen and (max-width: 1150px) {
      margin-left: 0.5rem;
    }

    @media only screen and (max-width: 992px) {
      margin-left: 1rem;
    }

    @media only screen and (max-width: 768px) {
      justify-content: center;

      a {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
      }
    }

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}
