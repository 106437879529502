@import "../../variables";

.graphic {
  padding: 0;
  padding-bottom: 1rem;

  img {
    width: 100%;
  }
}

.description {
  font-size: 2.25vw !important;

  @media only screen and (max-width: $desktop) {
    font-size: 2rem !important;
  }

  @media only screen and (max-width: $laptop) {
    font-size: 1.8rem !important;
  }

  @media only screen and (max-width: $tablet) {
    font-size: 1.6rem !important;
  }

  @media only screen and (max-width: $mobile) {
    font-size: 1.4rem !important;
  }
}

.input,
.textarea {
  background: #fefefe;
  border: 1px solid #000000 !important;
  outline: none !important;
  box-shadow: none !important;
  margin-top: 0.2rem;

  font-family: Overpass;
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
}

.input {
  height: 3rem;
}

.error {
  color: red;
}

.submit {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  background: $mid-blue;
  box-shadow: 0.64px 1.28px 5.13px #282828;
  border-radius: 0.2rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.7rem 2.5rem;

  p {
    font-family: Overpass;
    font-style: normal;
    font-weight: 600;
    font-size: 2.3vw;
    color: $white;
    margin: 0;

    @media only screen and (max-width: $desktop) {
      font-size: 2rem;
    }

    @media only screen and (max-width: $laptop) {
      font-size: 2.8rem;
    }

    @media only screen and (max-width: $tablet) {
      font-size: 1.6rem;
    }

    @media only screen and (max-width: $mobile) {
      font-size: 1.4rem;
    }

    @media only screen and (max-width: $small) {
      font-size: 1.2rem;
    }
  }
}
