@import "../../variables";

.mobile {
  display: none;
}

@media only screen and (max-width: $laptop) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

.navbar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2.5rem;
}

.logo {
  height: 4rem;

  img {
    height: 100%;
  }

  @media only screen and (max-width: $desktop) {
    height: 3rem;
  }

  @media only screen and (max-width: $tablet) {
    height: 2rem;
  }

  @media only screen and (max-width: $mobile) {
    height: 1.7rem;
  }
}

.nav-container {
  width: 70%;
}

.nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .nav-link {
    height: fit-content;
    margin-left: 1.6rem;
    text-decoration: none;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #777777;
    transition: 0.2s ease;

    &:hover {
      color: $black;
    }
  }

  @media only screen and (max-width: $tablet) {
    flex-direction: column;
    padding-top: 1rem;

    .nav-link {
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }

  .active {
    color: $black;
    text-decoration: underline;
  }

  .button {
    color: $black;
    padding: 0.3rem 1rem;
    padding-bottom: 0.1rem;
    margin-bottom: 0.2rem;
    border: 1px solid $black;
    box-shadow: 0.64px 1.28px 5.13px #282828;
    border-radius: 125px;
    transition: 0.3s ease;

    p {
      margin-bottom: 0;
    }

    &:hover {
      color: $white;
      background: #0000b7;
      border: 1px solid #000000;
    }
  }

  .active-button {
    .button {
      color: $white;
      background: #0000b7;
      border: 1px solid #000000;
    }
  }
}
