$dark-blue: #291d89;
$mid-blue: #0000b7;
$mid-light-blue: #0460ea;
$white: #ffffff;
$black: #000000;

$desktop: 1440px;
$laptop: 1024px;
$tablet: 768px;
$mobile: 586px;
$small: 440px;
