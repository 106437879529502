@import "../../../variables";

.logo {
  padding: 0;

  img {
    width: 80%;
    margin: 0 10%;

    @media only screen and (max-width: $tablet) {
      width: 90%;
      margin: 0 5%;
    }
  }
}

.line {
  border: 2px solid $mid-blue;
  opacity: 1;
}
