@import "../../../variables";

.mobile {
  display: none;
}

@media only screen and (max-width: $tablet) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

.splash {
  padding: 0;

  img {
    width: 100%;
  }
}

.line {
  border: 2px solid $mid-blue;
  opacity: 1;
}

.graphic {
  img {
    width: 80%;
    margin: 0 10%;
  }
}

.text-left,
.text-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-left {
  padding-right: 5%;
  padding-left: 2%;
}

.text-right {
  padding-left: 5%;
  padding-right: 2%;
}

.image {
  img {
    width: 100%;
    border-radius: 0.75rem;
  }
}
